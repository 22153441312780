import React, { FC } from 'react';
import TeamMember from '../../../About/components/TeamMember';
import { IAuthorHeader } from './AuthorHeader.def';
import BreadCrumbs from '../../../base/BreadCrumbs';
import { createSourceElements, createSrc, createSrcSet } from '../../../../utils';
import {
  LARGE_DESKTOP_MIN_WIDTH_MQ,
  LARGE_TAB_MIN_WIDTH_MQ,
  MEDIUM_TAB_MIN_WIDTH_MQ,
  MED_DESKTOP_MIN_WIDTH_MQ,
  MOBILE_MAX_WIDTH_MQ,
  SMALL_DESKTOP_MIN_WIDTH_MQ,
  SMALL_TAB_MIN_WIDTH_MQ,
} from '../../../../constants';

const AuthorHeader: FC<IAuthorHeader> = ({ headerBackground, breadCrumbs, ...rest }) => {
  const { src, alt } = headerBackground || {
    src: '/wp-content/uploads/2023/03/Malta-Ocean.jpg',
    alt: 'Malta Ocean',
  };

  const sourceElements = createSourceElements([
    {
      media: LARGE_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 1900,
            height: 1055,
          },
        },
      ]),
    },
    {
      media: MED_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 1420,
            height: 788,
          },
        },
      ]),
    },
    {
      media: SMALL_DESKTOP_MIN_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 1260,
            height: 700,
          },
        },
      ]),
    },
    {
      media: LARGE_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 1004,
            height: 576,
          },
        },
      ]),
    },
    {
      media: MEDIUM_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 740,
            height: 411,
          },
        },
      ]),
    },
    {
      media: SMALL_TAB_MIN_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 620,
            height: 344,
          },
        },
      ]),
    },
    {
      media: MOBILE_MAX_WIDTH_MQ,
      srcSet: createSrcSet(src || '', [
        {
          options: {
            width: 700,
          },
        },
      ]),
    },
  ]);
  return (
    <section className="aboutHeader">
      <div className="container">
        {breadCrumbs && <BreadCrumbs breadCrumbs={breadCrumbs} />}
        <div className="image-overlay image-overlayHorizontal_variant"></div>
        {src && alt && (
          <figure>
            <picture>
              {sourceElements}
              <img src={createSrc(src)} alt={alt} />
            </picture>
          </figure>
        )}
      </div>
      <TeamMember {...rest} />
    </section>
  );
};

export default AuthorHeader;
