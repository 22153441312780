import React from 'react';
import { SVGProps } from 'react';

const SliderArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.15208 21.5454C1.15208 32.7581 10.2418 41.8478 21.4546 41.8478C32.6673 41.8478 41.757 32.7581 41.757 21.5454C41.757 10.3326 32.6673 1.24289 21.4546 1.24289C10.2418 1.24289 1.15208 10.3326 1.15208 21.5454Z" stroke="#033358" stroke-width="1.30556"/>
    <path d="M19.0108 25.8537L13.7641 21.4434C13.536 21.4434 19.0108 17.2613 19.0108 17.2613" stroke="#033358" stroke-width="1.30556"/>
    <path d="M29.2 21.3674H14.0682" stroke="#033358" stroke-width="1.30556"/>
  </svg>
);

export default SliderArrow;
