import React from 'react';
import { graphql, HeadFC, PageProps } from 'gatsby';
import Seo from '../../components/Seo';
import Author from '../../components/Author/Author';
import { getAuthorDetails, getAuthorExtraDetails } from '../../utils/helpers';
import { getArticles, getFeaturedImageDataFromWpPost } from '../../utils';
import TeamMembersContainer from '../../containers/TeamMembersContainer';
// import authorsJson from '../../data/authors.json';
import { MAX_NUMBER_OF_POSTS_PER_PAGE } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IAuthorTemplateProps {
  author: Queries.WpUser;
  authorPosts: Queries.WpPageConnection;
  otherAuthors: Queries.WpUserConnection;
  seo: Queries.WpSEOUser;
}

const AuthorTemplate = ({ data, pageContext, location }: PageProps<IAuthorTemplateProps>) => {
  const { author, authorPosts, otherAuthors } = data;
  const authorDetails = getAuthorDetails(author, true, 300);

  let posts: Queries.WpPost[] = [];

  if (authorPosts.nodes && Array.isArray(authorPosts.nodes)) {
    posts = authorPosts.nodes;
  }
  let memberIds: number[] = [];
  if (otherAuthors.nodes && Array.isArray(otherAuthors.nodes)) {
    otherAuthors.nodes.forEach((node) => {
      if (node.databaseId) {
        memberIds.push(node.databaseId);
      }
    });
  }

  const articles = getArticles(posts).map((article) => ({
    ...article,
    thumbnail: {
      ...article.thumbnail,
      src: article.thumbnail.src,
    },
  }));

  const totalNumberOfPosts = (pageContext as any)?.totalNumberOfPosts || 0;
  const totalNumberOfPostsEdited =
    (author?.upgpAuthorDetails?.upgpAuthorCountEditedPosts as any) || 0;
  const uri = (pageContext as any)?.uri || '';
  const mainContent = {
    title: `About ${authorDetails.name}`,
    content: authorDetails.bio,
  };
  const moreLink =
    totalNumberOfPosts > MAX_NUMBER_OF_POSTS_PER_PAGE
      ? {
          text: 'See More Articles',
          link: `${uri}page/2/`,
        }
      : null;
  // const authorJson = authorsJson.find((authorItem) => authorItem.id === author.databaseId) || {};
  const extraAuthorDetails = getAuthorExtraDetails(author.upgpAuthorDetails);
  const authorGallery = author?.upgpAuthorDetails?.upgpAuthorTravelGallery;

  return (
    <HTPageWrapper category="Author" title={mainContent.title} location={location}>
      <Author
        mainContent={mainContent}
        authorHeader={{
          designation: authorDetails.designation,
          firstName: authorDetails.firstName,
          fullName: authorDetails.name,
          articlesPublished:
            totalNumberOfPosts !== 0
              ? `${totalNumberOfPosts} Published Article${totalNumberOfPosts > 1 ? 's' : ''}`
              : '',
          articlesEdited:
            totalNumberOfPostsEdited !== 0
              ? `${totalNumberOfPostsEdited} Edited Article${totalNumberOfPostsEdited > 1 ? 's' : ''}`
              : false,
          thumbnail: {
            alt: authorDetails.imageAlt,
            src: authorDetails.imageUrl,
          },
          isAuthorHeader: true,
          topicOfExpertise: authorDetails.topicOfExpertise,
          socials: authorDetails.socials,
          headerBackground: null,
          breadCrumbs: [
            {
              text: 'Home',
              link: '/',
            },
          ],
        }}
        authorArticles={{
          articles,
          title: `${author.firstName}'s Latest Articles`,
          moreLink,
        }}
        // quickInfo={authorJson}
        quickInfo={extraAuthorDetails}
        galleryImages={authorGallery}
      >
        <TeamMembersContainer
          upgp_gb_meet_the_team={memberIds}
          upgp_gb_meet_the_team_variant=""
          upgp_gb_meet_the_team_title="Find out about other members of the team"
        />
      </Author>
    </HTPageWrapper>
  );
};

export default AuthorTemplate;

export const Head: HeadFC<IAuthorTemplateProps> = ({ pageContext, data }) => {
  const { author, authorPosts } = data;
  const wpSeo = author?.seo;
  const { uri: uriPrefix, totalNumberOfPages } = pageContext as any;
  const authorName = author?.name || '';
  const firstPost = authorPosts?.nodes[0];
  const seo = { ...wpSeo };
  seo.opengraphUrl = wpSeo?.canonical;
  seo.opengraphType = 'profile';
  if (totalNumberOfPages > 1) {
    seo.nextUrl = uriPrefix + 'page/2';
  }
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  return (
    <Seo
      title={`${authorName}, ${author.description}`}
      wpSeo={seo}
      featuredImageData={featuredImageData}
      author={author}
      isAuthorPage={true}
    />
  );
};

export const query = graphql`
  query Authors($id: String, $limit: Int) {
    author: wpUser(id: { eq: $id }) {
      name
      databaseId
      firstName
      avatar {
        url
      }
      description
      userSocials {
        upgpUserExpertise {
          id
          name
        }
        upgpUserEmail
        upgpUserSocialLinks {
          upgpUserSocialLinksName
          upgpUserSocialLinksUrl
        }
      }
      userBios {
        upgpUserRichBio
      }
      upgpAuthorDetails {
        upgpCountriesVisited
        upgpUsStatesVisited
        upgpAuthorDetailsHighestHotelStatus
        upgpAuthorDetailsHighestAirlineStatus
        upgpAuthorDetailsFavoriteAirlineAlliance
        upgpAuthorDetailsFavoriteFirstClass
        upgpAuthorDetailsFavoriteBusinessClass
        upgpAuthorDetailsBestRedemptionValue
        upgpAuthorDetailsFavoriteTypePoints
        upgpAuthorDetailsTopThreeCardsWallet
        upgpAuthorCountEditedPosts
        upgpAuthorTravelGallery {
          altText
          caption
          description
          sourceUrl
          srcSet
        }
      }
      seo {
        title
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        metaDesc
        opengraphDescription
        opengraphImage {
          height
          sourceUrl
          width
          mediaType
        }
        opengraphTitle
        schema {
          raw
        }
      }
    }
    authorPosts: allWpPost(
      filter: { author: { node: { id: { eq: $id } } } }
      limit: $limit
      sort: { date: DESC }
    ) {
      nodes {
        title
        uri
        excerpt
        dateGmt
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
        author {
          node {
            uri
            name
          }
        }
      }
    }
    otherAuthors: allWpUser(
      filter: { posts: { nodes: { elemMatch: { id: { regex: "/(.*?)/" } } } }, id: { ne: $id } }
    ) {
      nodes {
        databaseId
      }
    }
  }
`;
