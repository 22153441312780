import React, { FC } from 'react';
import { IAuthorMainContent } from './AuthorMainContent.def';
import { processHTMLContent } from '../../../../utils/processHTMLContent';

const AuthorMainContent: FC<IAuthorMainContent> = ({ title, content }) => {
  return (
    <div className="main">
      <h2>{title}</h2>
      {typeof content === 'string' ? processHTMLContent(content) : content}
    </div>
  );
};

export default AuthorMainContent;
