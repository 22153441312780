import React, { useEffect, useState } from 'react';
import TimesIcon from '../../svgs/TimesIcon';
import { LightboxProps } from './AuthorGallery.def';
import SliderArrow from '../../svgs/SliderRoundedArrow';

const Lightbox: React.FC<LightboxProps> = ({ isOpen, images, initialIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  useEffect(() => {
    if (isOpen) {
      setCurrentIndex(initialIndex);
    }
  }, [isOpen, initialIndex]);

  const handlePrev = () => {
    const newIndex = currentIndex > 0 ? currentIndex - 1 : images.length - 1;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = currentIndex < images.length - 1 ? currentIndex + 1 : 0;
    setCurrentIndex(newIndex);
  };

  if (!isOpen) return null;

  return (
    <div className={`lightbox-overlay ${isOpen ? 'fade-in' : 'fade-out'}`} onClick={onClose}>
      <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
        <img
          key={images[currentIndex]?.src || ''}
          src={images[currentIndex]?.src || ''}
          alt={images[currentIndex]?.altText || ''}
          className="lightbox-image"
          decoding="async"
        />
        <div className="lightbox-text">
          {images[currentIndex]?.description && (
            <div className="lightbox-description" dangerouslySetInnerHTML={{ __html: images[currentIndex]?.description || '' }} />
          )}
          {images[currentIndex]?.caption && (
            <div className="lightbox-caption" dangerouslySetInnerHTML={{ __html: images[currentIndex]?.caption || '' }} />
          )}
        </div>
        <button className="lightbox-close" onClick={onClose}><TimesIcon /></button>
        <button className="lightbox-prev" onClick={handlePrev}><SliderArrow /></button>
        <button className="lightbox-next" onClick={handleNext}><SliderArrow /></button>
        <button className="lightbox-close-link" onClick={onClose}>close</button>
      </div>
    </div>
  );
};

export default Lightbox;
