import React, { FC } from 'react';
import { IAuthor } from './Author.def';
import TeamMembers from '../About/components/TeamMembers/TeamMembers';
import GuideBlocks from '../GuideBlocks';
import AuthorHeader from './components/AuthorHeader/AuthorHeader';
import AuthorMainContent from './components/AuthorMainContent/AuthorMainContent';
import AuthorQuickInfo from './components/AuthorQuickInfo/AuthorQuickInfo';
import AuthorGallery from '../AuthorGallery';
const Author: FC<IAuthor> = ({
  authorHeader,
  mainContent,
  quickInfo,
  authorArticles,
  otherAuthors,
  children,
  galleryImages,
}) => {
  return (
    <div className="author-wrapper">
      <AuthorHeader {...authorHeader} />
      <AuthorGallery images={galleryImages} authorName={authorHeader.firstName}/>
      <section className="template2col">
        <div className="container">
          <AuthorMainContent {...mainContent} />
          <AuthorQuickInfo {...quickInfo} />
        </div>
      </section>
      {authorArticles?.articles?.length ? <GuideBlocks {...authorArticles} /> : null}
      {otherAuthors && <TeamMembers {...otherAuthors} />}
      {children}
    </div>
  );
};

export default Author;
