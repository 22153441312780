import React, { useState, useEffect } from 'react';
import { IWPImageData, IWPGalleryImages } from './AuthorGallery.def';
import Lightbox from './Lightbox';
import useIsMobile from '../../hooks/useIsMobile';
import { replaceDomainWithSiteDomain } from '../../utils/helpers';

// Function to extract URLs for thumbnails and large images
const getImageSizesFromSrcset = (srcSet: string, fullSize: string) => {
  const sources = srcSet.split(',').map(src => src.trim()) || [];
  let thumbnail = '';
  let largeImage = '';
  sources.forEach(source => {
    if (source.endsWith('780w')) {
      thumbnail = source.split(' ')[0] || fullSize;
    } else if (source.endsWith('1536w')) {
      largeImage = source.split(' ')[0] || fullSize;
    }
  });
  return { thumbnail, largeImage: largeImage || fullSize };
};

// Component that renders an image
const ImageComponent: React.FC<IWPImageData & { isVisible: boolean; onClick: () => void }> = ({ altText, sourceUrl, srcSet, isVisible, onClick }) => {
  const { thumbnail } = getImageSizesFromSrcset(srcSet, sourceUrl);
  const thumbnailSrc: string = replaceDomainWithSiteDomain(thumbnail || '') || '';
  return (
    <div className="image-container" onClick={onClick} style={{ display: isVisible ? 'block' : 'none' }}>
      <img src={thumbnailSrc} alt={altText} className="image-element" loading="lazy" />
    </div>
  );
};

// Main component that receives image data and renders the image gallery
const AuthorGallery: React.FC<IWPGalleryImages> = ({ images, authorName }) => {
  const isMobile = useIsMobile();
  const [currentPage, setCurrentPage] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imagesPerPage, setImagesPerPage] = useState(12);
  useEffect(() => {
    if (isMobile) {
      setImagesPerPage(9);
    } else {
      setImagesPerPage(12);
    }
  }, [isMobile]);

  // Validate if images is an array and contains values
  if (!Array.isArray(images) || images.length === 0) {
    return null;
  }

  // Function to safely extract image data
  const extractImageData = (image: any): IWPImageData | null => {
    if (image && typeof image === 'object') {
      const { altText, caption, description, sourceUrl, srcSet } = image;
      if (typeof altText === 'string' && typeof sourceUrl === 'string' && typeof srcSet === 'string') {
        return {
          altText,
          caption: caption || null,
          description: description || null,
          sourceUrl,
          srcSet,
        };
      }
    }
    return null;
  };

  // Filter and map the image data
  const validImages = images.map(extractImageData).filter((img): img is IWPImageData => img !== null);

  // Calculate the total number of pages
  const totalPages = Math.ceil(validImages.length / imagesPerPage);

  const handlePageChange = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const lightboxImages = validImages.map(image => {
    const { largeImage } = getImageSizesFromSrcset(image.srcSet, image.sourceUrl);
    return {
      src: replaceDomainWithSiteDomain(largeImage),
      caption: image.caption || '',
      altText: image.altText || '',
      description: image.description || image.altText,
    };
  });

  return (
    <section className="author-gallery">
      <div className="container">
        <h2 className="gallery-heading">{authorName + `'s Travel Gallery`}</h2>
        <div className="image-gallery">
          <div id="image-grid-container">
            {validImages.map((image, index) => (
              <ImageComponent
                key={index}
                {...image}
                isVisible={Math.floor(index / imagesPerPage) === currentPage}
                onClick={() => handleImageClick(index)}
              />
            ))}
          </div>
          <div className="gallery-pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`page-button ${index === currentPage ? 'active' : ''}`}
                onClick={() => handlePageChange(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <Lightbox
            isOpen={lightboxOpen}
            images={lightboxImages}
            initialIndex={selectedImageIndex}
            onClose={closeLightbox}
          />
        </div>
      </div>
    </section>
  );
};

export default AuthorGallery;
