import React, { FC } from 'react';
import countriesIcon from '../../../../images/icon-countriesVisited.svg';
import usStatesIcon from '../../../../images/icon-USstatesVisited.svg';
import { IAuthorQuickInfo } from './AuthorQuickInfo.def';
import { processHTMLContent } from '../../../../utils/processHTMLContent';

const AuthorQuickInfo: FC<IAuthorQuickInfo> = ({
  bestRedemptionValue,
  countriesVisited,
  favoriteAirlineAlliance,
  favoriteFirstClass,
  favoriteTypeOfPoints,
  favoriteBusinessClass,
  highestAirlineStatus,
  highestHotelStatus,
  topThreeCardsInWallet,
  usStatesVisited,
}) => {
  return (
    <aside className="aside aboutQuickInfo">
      <div className="withIcon">
        <figure className="countries-visited">
          <img src={countriesIcon} alt="Countries visited icon" />
        </figure>
        <div>
          <label>{countriesVisited ? countriesVisited : '-'}</label>
          <p>Countries Visited</p>
        </div>
      </div>
      <div className="withIcon">
        <figure className="us-states">
          <img src={usStatesIcon} alt="US states icon" />{' '}
        </figure>
        <div>
          <label>{usStatesVisited ? usStatesVisited : '-'}</label>
          <p>U.S. States Visited</p>
        </div>
      </div>
      <div>
        <label>Highest Hotel Status</label>
        <p>{highestHotelStatus ? processHTMLContent(highestHotelStatus) : '-'}</p>
      </div>
      <div>
        <label>Highest Airline Status</label>
        <p>{highestAirlineStatus ? processHTMLContent(highestAirlineStatus) : '-'}</p>
      </div>
      <div>
        <label>Favorite Airline Alliance</label>
        <p>{favoriteAirlineAlliance ? processHTMLContent(favoriteAirlineAlliance) : '-'}</p>
      </div>
      <div>
        <label>Favorite First Class</label>
        <p>{favoriteFirstClass ? processHTMLContent(favoriteFirstClass) : '-'}</p>
      </div>
      <div>
        <label>Favorite Business Class</label>
        <p>{favoriteBusinessClass}</p>
      </div>
      <div>
        <label>Best Redemption Value</label>
        <p>{bestRedemptionValue}</p>
      </div>
      <div>
        <label>Favorite Type of Points</label>
        <p>{favoriteTypeOfPoints ? favoriteTypeOfPoints : '-'}</p>
      </div>
      <div>
        <label>Top 3 Cards in Wallet</label>
        <p className="top3CreditCards">{topThreeCardsInWallet}</p>
      </div>
    </aside>
  );
};

export default AuthorQuickInfo;
